import React from 'react';
import { TenantManager } from '../../utils/TenantManager/TenantManager';

/**
 * Hook to get the tenantId from the TenantManager
 * @returns the tenantId or null if not found
 */
export const useTenantId = (): string | null => {
    const [tenantId, setTenantId] = React.useState(TenantManager.getTenantId());

    React.useEffect(() => {
        const listener = (newTenantId: string | null): void => setTenantId(newTenantId);
        TenantManager.addTenantIdChangeListener(listener);

        return (): void => {
            TenantManager.removeTenantIdChangeListener(listener);
        };
    }, []);

    return tenantId;
};

export default useTenantId;
