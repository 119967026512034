import { type History, createHashHistory } from 'history';

const TENANT_COOKIE_INDEX = 'Tenant=';
const TENANT_URL_PARAM = 'tenant';

interface SetTenantCookieParams {
    suppressReload?: boolean;
}

interface SetTenantUrlParams {
    /**
     * How to update the URL, if at all
     * @default 'none'
     */
    urlUpdateMode?: 'none' | 'replace' | 'push';
}

interface TenantSaveParams extends SetTenantCookieParams, SetTenantUrlParams {
    // pass
}

interface TenantListener {
    (tenantId: string | null): void;
}

interface TenantListenerDetails {
    lastCalledWith: string | null;
}

interface TenantListenerOptions {
    /**
     * If true, the listener will be called immediately with the current tenantId
     * @default false
     */
    callImmediately?: boolean;
}

const _TenantListeners: Map<TenantListener, TenantListenerDetails> = new Map();

/**
 * A class to manage the tenantId stored in the application
 */
export class TenantManager {
    private static _history = createHashHistory();

    // --------------- PUBLIC METHODS ---------------

    /**
     * Sets the history object to use for updating the URL, defaults to {@link createHashHistory}
     */
    public static setHistory(history: History): void {
        this._history = history;
    }

    /**
     * Gets the tenantId from the URL or cookie, in that order of priority
     * @returns the tenantId or null if not found
     */
    public static getTenantId(): string | null {
        const tenantId = this._getTenantIdFromURL() ?? this._getTenantIdFromCookie();
        return tenantId;
    }

    /**
     * Sets the tenantId to the cookie and URL
     * @param tenantId the id to save
     */
    public static setTenantId(tenantId: string | null, params: TenantSaveParams = {}): void {
        this._setTenantIdToCookie(tenantId, params);
        this._setTenantIdToURL(tenantId, params);

        this._callListeners(tenantId);
    }

    public static addTenantIdChangeListener(callback: TenantListener, options?: TenantListenerOptions): void {
        const { callImmediately = false } = options ?? {};

        _TenantListeners.set(callback, { lastCalledWith: null });
        if (callImmediately) this._callListener(callback, this.getTenantId());
    }

    public static removeTenantIdChangeListener(callback: TenantListener): void {
        _TenantListeners.delete(callback);
    }

    // --------------- PRIVATE METHODS ---------------

    /**
     * Calls the listener with the tenantId
     * @param listener the listener to call
     * @param tenantId the tenantId to pass to the listener
     */
    private static _callListener(listener: TenantListener, tenantId: string | null): void {
        const details = _TenantListeners.get(listener);
        if (details) {
            details.lastCalledWith = tenantId;
            listener(tenantId);
        }
    }

    /**
     * Calls all listeners with the tenantId
     * @param tenantId the tenantId to pass to the listeners
     */
    private static _callListeners(tenantId: string | null): void {
        _TenantListeners.forEach((_, listener) => {
            this._callListener(listener, tenantId);
        });
    }

    /**
     * Gets the tenantId from the cookie
     * @returns the tenantId or null if not found
     */
    private static _getTenantIdFromCookie(): string | null {
        const ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') c = c.substring(1, c.length);
            if (c.indexOf(TENANT_COOKIE_INDEX) === 0) {
                return c.substring(TENANT_COOKIE_INDEX.length, c.length);
            }
        }
        return null;
    }

    /**
     * Sets the tenantId to the cookie
     * @param tenantId the id to save
     */
    private static _setTenantIdToCookie(tenantId: string | null, options?: SetTenantCookieParams): void {
        const { suppressReload = false } = options ?? {};

        const currentTenantId = this.getTenantId();
        const needsRefresh = !suppressReload && tenantId !== currentTenantId;

        let expires = '';

        if (!tenantId) expires = '; expires=Thu, 01 Jan 1970 00:00:00 UTC';
        else {
            const days = 30;
            const date = new Date();
            date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
            expires = `; expires=${date.toUTCString()}`;
        }

        document.cookie = `${TENANT_COOKIE_INDEX}${tenantId || ''}${expires}; path=/`;

        this._clearTenantIdFromUrl(); // Remove the tenantId from the URL
        if (needsRefresh) document.location.reload();
    }

    private static _clearTenantIdFromUrl(): void {
        const { search } = this._history.location;
        if (!search) return;

        const searchParams = new URLSearchParams(search);
        searchParams.delete(TENANT_URL_PARAM);
        this._history.replace({ search: `?${searchParams.toString()}` });
    }

    /**
     * Gets the tenantId from the URL and moves it to the cookie
     * @returns the tenantId or null if not found
     */
    private static _getTenantIdFromURL(): string | null {
        const { search } = this._history.location;

        const urlParams = new URLSearchParams(search);
        const tenantValue = urlParams.get(TENANT_URL_PARAM);

        return tenantValue;
    }

    /**
     * Sets the tenantId to the URL
     * @param tenantId the id to save
     */
    private static _setTenantIdToURL(tenantId: string | null, params?: SetTenantUrlParams): void {
        const { urlUpdateMode = 'none' } = params ?? {};
        if (urlUpdateMode === 'none') return;

        const { search } = this._history.location;
        const searchParams = new URLSearchParams(search);

        if (tenantId) searchParams.set(TENANT_URL_PARAM, tenantId);
        else searchParams.delete(TENANT_URL_PARAM);

        const newSearch = `?${searchParams.toString()}`;

        switch (urlUpdateMode) {
            case 'push':
                this._history.push({ search: newSearch });
                break;
            case 'replace':
            default:
                this._history.replace({ search: newSearch });
                break;
        }
    }
}

export default TenantManager;
