import { ClientConfiguration } from './models/ClientConfiguration';

interface ClientConfigurationCacheType {
    value: ClientConfiguration | undefined;
}

export const ClientConfigurationCache: ClientConfigurationCacheType = { value: undefined };

export const getClientConfiguration = async (): Promise<ClientConfiguration> => {
    const { value } = ClientConfigurationCache;
    if (value) return value;

    const response = await fetch(`/api/ClientConfiguration`);
    const clientConfiguration = (await response.json()) as ClientConfiguration;

    ClientConfigurationCache.value = clientConfiguration;

    return clientConfiguration;
};

export default getClientConfiguration;
