import React from 'react';
import { getClientConfiguration } from '../../ClientConfiguration';
import { ClientConfiguration } from '../../models/ClientConfiguration';

interface ClientConfigurationResponse {
    isLoading: boolean;
    isError: boolean;
    error?: unknown;
    result?: ClientConfiguration;
}

export const useClientConfiguration = (): ClientConfigurationResponse => {
    const [response, setResponse] = React.useState<ClientConfigurationResponse>({ isLoading: false, isError: false });

    React.useEffect(() => {
        let attempts = 0;
        const fetch = (): void => {
            attempts++;
            setResponse({ isLoading: true, isError: false });

            getClientConfiguration()
                .then((r) => {
                    setResponse({ isLoading: false, isError: false, result: r });
                })
                .catch((error) => {
                    setResponse({ isLoading: false, isError: true, error });
                    if (attempts < 3) setTimeout(fetch, 5000);
                });
        };

        fetch();
    }, []);

    return response;
};

export default useClientConfiguration;
